<template>
  <div class="physicalReport">
    <!-- 切换就诊人 -->
    <SwitchPeople />
    <template v-if="reportList.length != 0">
      <div
        class="physicalReportList"
        v-for="(item, index) in reportList"
        :key="index"
      >
        <div class="reportItem">
          <div class="reportItemCh">体检时间：</div>
          <div class="reportItemContent">
            {{ item.health_date.split('.')[0] }}
          </div>
        </div>
        <div class="reportItem">
          <div class="reportItemCh">报告时间：</div>
          <div class="reportItemContent">
            {{ item.report_date.split('.')[0] }}
          </div>
        </div>
        <div class="reportItem">
          <div class="reportItemCh">状态：</div>
          <div class="reportItemContent">{{ item.status }}</div>
        </div>
        <div class="reportPdfBtn">
          <van-button
            class="buttonColor"
            size="small"
            round
            type="info"
            @click="reportPDF(item.report_no)"
          >
            查看
          </van-button>
        </div>
      </div>
    </template>
    <van-empty v-else description="暂无体检报告" />
  </div>
</template>

<script>
import { healthReportList } from '@/network/service'
import { fun_date } from '@/common/utils'
import SwitchPeople from '@/components/common/switchPeople/switchPeople'
export default {
  name: 'physicalReport',
  data() {
    return {
      reportList: [],
    }
  },
  components: {
    SwitchPeople,
  },
  mounted() {
    this.getHealthReportList()
  },
  methods: {
    async getHealthReportList() {
      let res = await healthReportList({
        id_no: this.$Base64.decode(this.$store.state.cardList[0].idNo),
        name: this.$store.state.cardList[0].name,
        begin_time: fun_date(-183)[1],
        end_time: fun_date(-183)[0],
      })
      if (res.code == 0) {
        this.reportList = res.data.root.body.detail
      } else {
        this.$toast(res.msg)
      }
    },
    reportPDF(reportNo) {
      this.$router.push({ path: '/physicalPDF', query: { reportNo } })
    },
  },
}
</script>

<style lang="less" scoped>
.physicalReport {
  width: 100vw;
  height: 100vh;
  background: #f5f5f5;
  .physicalReportList {
    width: 95%;
    margin: auto;
    padding: 0.7rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    background: #fff;
    .reportItem {
      height: 1.5rem;
      line-height: 1.5rem;
      display: flex;
      .reportItemCh {
        flex: 0.25;
      }
      .reportItemContent {
        flex: 0.75;
      }
    }
    .reportPdfBtn {
      text-align: right;
      .buttonColor {
        background: @darkColor;
        border-color: @darkColor;
      }
    }
  }
  .physicalReportList:nth-child(2) {
    margin-top: 0.5rem;
  }
}
</style>
